<template>
    <div>
        <CustomHeader title="天气环境" @change="getData"></CustomHeader>
        <div class="layout">
        <el-row :gutter="20">
            <el-col :xs="24" :md="6">
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">未来7日气温变化</div>
                        <div class="item-content">
                            <eCharts :options="option1"></eCharts>
                        </div>
                    </div>
                </dv-border-box-12>
            </el-col>
            <el-col :xs="24" :md="12">
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">景区天气情况</div>
                        <div class="item-content">
                           <div v-for="(item,index) in list['date1']" :key="index" class="weather-info">
                               <div>{{item['week']}}</div>
                               <div><img :src="item['img']" width="30px" height="30px"></div>
                               <div>{{item['templow']+'°/'+item['temphigh']+'° '+item['weather']+'转'+item['weather2']}}</div>
                               <div>{{item['winddirect']+' '+item['windpower']+'-'+item['windpower2']}}</div>
                           </div>
                        </div>
                    </div>
                </dv-border-box-12>
            </el-col>
            <el-col :xs="24" :md="6">
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">出行建议</div>
                        <div class="item-content">
                            <dv-decoration-3 style="width:90%;height:30px;" />
                            <div class="item-swiper">
                                <el-carousel :interval="5000" direction="vertical" indicator-position="none">
                                    <el-carousel-item v-for="(item,index) in list['date3']" :key="index">
                                        <div class="swiper-name">{{item['iname']}}:{{item['ivalue']}}</div>
                                        <div class="swiper-detail">{{item['detail']}}</div>
                                    </el-carousel-item>
                                </el-carousel>
                            </div>
                        </div>
                    </div>
                </dv-border-box-12>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :xs="24" :md="6">
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">过去一周空气质量统计</div>
                        <div class="item-content qulity-number">
                            <div class="item-sub">
                                <div class="item-subtitle">一级(优)</div>
                                <dv-digital-flop :config="{number:[list['date6']['aqi1']],content:'{nt}天',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                            </div>
                            <div class="item-sub">
                                <div class="item-subtitle">二级(良)</div>
                                <dv-digital-flop :config="{number:[list['date6']['aqi2']],content:'{nt}天',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                            </div>
                            <div class="item-sub">
                                <div class="item-subtitle">轻度污染</div>
                                <dv-digital-flop :config="{number:[list['date6']['aqi3']],content:'{nt}天',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                            </div>
                            <div class="item-sub">
                                <div class="item-subtitle">中度污染</div>
                                <dv-digital-flop :config="{number:[list['date6']['aqi4']],content:'{nt}天',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                            </div>
                            <div class="item-sub">
                                <div class="item-subtitle">重度污染</div>
                                <dv-digital-flop :config="{number:[list['date6']['aqi5']],content:'{nt}天',style: {fontSize: 26,fill: echarts_color1},formatter}" />
                            </div>
                        </div>
                    </div>
                </dv-border-box-12>
            </el-col>
            <el-col :xs="24" :md="12">
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">今日空气质量状况</div>
                        <div class="item-content">
                            <div class="weather-quality">
                                <div>更新时间:{{list['date4']['timepoint']}}</div>
                                <div>空气质量等级:{{list['date4']['quality']}}</div>
                                <div class="quality-flex">
                                    <template v-for="(value,key,index) in list['date4']" >
                                        <div v-if="key!=='aqiinfo' && key!=='timepoint' && key!=='quality'" :key="index">
                                            <div style="font-size: 24px;">{{value}}</div>
                                            <div style="font-size: 14px;">{{key}}</div>
                                        </div>
                                    </template>
                                </div>
                                <div>数值单位：μg/m3(CO为mg/m3)</div>
                            </div>
                        </div>
                    </div>
                </dv-border-box-12>
            </el-col>
            <el-col :xs="24" :md="6">
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">今年空气质量占比</div>
                        <div class="item-content">
                            <dv-active-ring-chart :config="option3"/>
                        </div>
                    </div>
                </dv-border-box-12>
            </el-col>
        </el-row>
        <el-row :gutter="20">
            <el-col :xs="24" :md="12">
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">未来24小时温度指标</div>
                        <div class="item-content">
                            <eCharts :options="option4"></eCharts>
                        </div>
                    </div>
                </dv-border-box-12>
            </el-col>
            <el-col :xs="24" :md="12">
                <dv-border-box-12>
                    <div class="item-box1">
                        <div class="item-title">近12个月空气质量等级统计</div>
                        <div class="item-content">
                            <eCharts :options="option5"></eCharts>
                        </div>
                    </div>
                </dv-border-box-12>
            </el-col>
        </el-row>
    </div>
    </div>
</template>

<script>
    import eCharts from "../components/echarts"
    import {getWeatherInfo} from "../api/common";
    import CustomHeader from "../components/CustomHeader";
    import {mapState} from "vuex";
    export default {
        name: "Weather",
        components:{eCharts,CustomHeader},
        computed:{
            ...mapState(['linear_color','echarts_color1','echarts_color2','echarts_color3','echarts_color4','echarts_color5'])
        },
        data(){
            return{
                list:{
                    date4:{
                        timepoint:'',
                        quality:''
                    },
                    date6:{
                        aqi1:0,
                        aqi2:0,
                        aqi3:0,
                        aqi4:0,
                        aqi5:0,
                        aqi6:0,
                    }
                },
                option1:{},
                option2:{},
                option3:{},
                option4:{},
                option5:{},
                option1_list:[],
                option1_index:0,
                option5_list:[],
                option5_index:0
            }
        },
        mounted() {
            this.getData()
            setInterval(()=>{
                this.getData()
            },60*1000)
            this.changeDate()
        },
        methods:{
            async getData(e) {
                let res = await getWeatherInfo(e)
                this.list = res.data
                this.option1_list=[
                    {
                        name: '最低气温',
                        type: 'line',
                        data: res.data['date2']['templow'],
                        label:{
                            show:true
                        },
                        color:this.echarts_color1
                    },
                    {
                        name: '最高气温',
                        type: 'line',
                        data: res.data['date2']['temphigh'],
                        label:{
                            show:true
                        },
                        color:this.echarts_color2
                }]
                this.option5_list= [
                    {
                    name: '一级(优)',
                    type: 'line',
                    data: res.data['date8']['api1'],
                    color:this.echarts_color1,
                    label:{show:true}
                    },
                    {
                        name: '二级(良)',
                        type: 'line',
                        data: res.data['date8']['api2'],
                        color:this.echarts_color2,
                        label:{show:true}
                    },
                    {
                        name: '轻度污染',
                        type: 'line',
                        data: res.data['date8']['api3'],
                        color:this.echarts_color3,
                        label:{show:true}
                    },
                    {
                        name: '中度污染',
                        type: 'line',
                        data: res.data['date8']['api4'],
                        color:this.echarts_color4,
                        label:{show:true}
                    },
                    {
                        name: '重度污染',
                        type: 'line',
                        data: res.data['date8']['api5'],
                        color:this.echarts_color5,
                        label:{show:true}
                    }];
                this.option1 = {
                    baseOption:{
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            data: ['最低气温', '最高气温'],
                            textStyle: {
                                color: 'white',
                                fontSize: 14
                            },
                        },
                        grid: {
                            top:'20%',
                            left: '10%',
                            right: '12%',
                            bottom:0,
                            containLabel: true,
                        },
                        xAxis: {
                            type: 'category',
                            axisLine: {
                                lineStyle: {
                                    color: 'white',
                                },
                            },
                            axisLabel: {
                                fontSize: 14
                            },
                            boundaryGap: false,
                            data: res.data['date2']['data'],
                        },
                        yAxis: {
                            splitNumber: 3,
                            type: 'value',
                            axisLine: {
                                show: false
                            },
                            axisLabel: {
                                fontSize: 14
                            },
                        },
                        series: [{
                            name: '最低气温',
                            type: 'line',
                            data: res.data['date2']['templow'],
                            label:{
                                show:true
                            },
                            color:this.echarts_color1
                        }],
                        textStyle: {
                            color: 'white'
                        },
                    },
                    media: [ // 媒体查询
                        {
                            query: { // 这里写规则
                                maxWidth: 350
                            },
                            option: { // 这里写此规则满足下的 option
                                visualMap:{
                                    show:false
                                },
                            }
                        },
                    ],
                };
                this.option3={
                    data: [
                        {
                            name: '一级(优)',
                            value: res.data['date7']['aqi1']
                        },
                        {
                            name: '二级(良)',
                            value: res.data['date7']['aqi2']
                        },
                        {
                            name: '轻度污染',
                            value: res.data['date7']['aqi3']
                        },
                        {
                            name: '中度污染',
                            value: res.data['date7']['aqi4']
                        },
                        {
                            name: '重度污染',
                            value: res.data['date7']['aqi5']
                        },
                    ],
                    radius: '75%',
                    activeRadius: '80%',
                    digitalFlopStyle: {
                        fontSize: 14
                    }
                }
                this.option4 = {
                    baseOption:{
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            data: ['温度'],
                            textStyle: {
                                color: 'white',
                                fontSize: 14
                            },
                        },
                        grid: {
                            top:'20%',
                            left: '5%',
                            right: '5%',
                            bottom:0,
                            containLabel: true,
                        },
                        xAxis: {
                            type: 'category',
                            axisLine: {
                                lineStyle: {
                                    color: 'white'
                                }
                            },
                            axisLabel: {
                                fontSize: 14
                            },
                            boundaryGap: false,
                            data: res.data['date5']['time'],
                        },
                        yAxis: {
                            splitNumber: 2,
                            type: 'value',
                            axisLine: {
                                show: false
                            },
                            axisLabel: {
                                fontSize: 14
                            },
                        },
                        series: [{
                            name: '温度',
                            type: 'line',
                            data: res.data['date5']['temp'],
                            label:{
                                show:true
                            },
                            itemStyle:{
                                color:this.linear_color
                            }
                        }],
                        textStyle: {
                            color: 'white'
                        },
                    },
                    media: [ // 媒体查询
                        {
                            query: { // 这里写规则
                                maxWidth: 350
                            },
                            option: { // 这里写此规则满足下的 option
                                dataZoom: [//给x轴设置滚动条
                                    {
                                        type: 'inside',
                                        realtime: true,
                                        startValue: 0,
                                        endValue: 10
                                    }
                                ],
                            }
                        },
                    ],
                };
                this.option5 = {
                    baseOption:{
                        tooltip: {
                            trigger: 'axis'
                        },
                        legend: {
                            data: ['一级(优)', '二级(良)', '轻度污染', '中度污染', '重度污染'],
                            textStyle: {
                                color: 'white',
                                fontSize: 14
                            }
                        },
                        grid: {
                            top:'20%',
                            left: '5%',
                            right: '5%',
                            bottom:0,
                            containLabel: true
                        },
                        xAxis: {
                            type: 'category',
                            axisLine: {
                                lineStyle: {
                                    color: 'white'
                                }
                            },
                            axisLabel: {
                                fontSize: 14
                            },
                            boundaryGap: false,
                            data: res.data['date8']['date']
                        },
                        yAxis: {
                            type: 'value',
                            splitNumber: 2,
                            axisLine: {
                                show: false
                            },
                            axisLabel: {
                                fontSize: 14
                            },
                        },
                        series: [{
                            name: '一级(优)',
                            type: 'line',
                            data: res.data['date8']['api1'],
                            color:this.echarts_color1,
                            label:{show:true}
                        }],
                        textStyle: {
                            color: 'white'
                        },
                    },
                    media: [ // 媒体查询
                        {
                            query: { // 这里写规则
                                maxWidth: 350
                            },
                            option: { // 这里写此规则满足下的 option
                                dataZoom: [//给x轴设置滚动条
                                    {
                                        type: 'inside',
                                        realtime: true,
                                        startValue: 0,
                                        endValue: 10
                                    }
                                ],
                                legend: {
                                    textStyle: {
                                        color: 'white',
                                        fontSize: 8
                                    }
                                },
                            }
                        },
                    ],
                };
            },
            formatter(number) {
                const numbers = number.toString().split('').reverse()
                const segs = []
                while (numbers.length) segs.push(numbers.splice(0, 3).join(''))
                return segs.join(',').split('').reverse().join('')
            },
            changeDate(){
                setInterval(()=>{
                    if(this.option1_index<this.option1_list.length-1){
                        this.option1_index++
                    }
                    else{
                        this.option1_index=0
                    }
                    if(this.option5_index<this.option5_list.length-1){
                        this.option5_index++
                    }
                    else{
                        this.option5_index=0
                    }
                    this.option1.baseOption.series=this.option1_list[this.option1_index]
                    this.option5.baseOption.series=this.option5_list[this.option5_index]
                },5000)
            },
        }
    }
</script>

<style scoped lang="scss">
    @import "src/static/css/common";
    .weather-info{
        color: white;
        margin-bottom: 15px;
        text-align: center;
        div{
            margin: 7px 0;
        }
        div:nth-of-type(1){
            font-size: 18px;
        }
        div:nth-of-type(3){
            font-size: 16px;
        }
        div:nth-of-type(4){
            font-size: 14px;
        }
    }
.item-swiper{
    width: 85%;
    height: 100%;
    padding: 10px;
    font-size: 16px;
    color: white;
    overflow: hidden;
    .swiper-detail{
        text-indent:2em;
        font-size: 14px;
        margin-top: 15px;
        color: #e5e5e5;
    }
}
    .weather-quality{
        color: white;
        text-align: center;
        font-size: 14px;
        margin-bottom: 10px;
        width: 100%;
        .quality-flex{
            display: flex;
            margin: 10px 0;
            justify-content: space-evenly;
            align-items: center;
            &>div{
                white-space: nowrap;
                margin: 0 15px;
            }
        }
    }
    .qulity-number{
        .item-sub{
            max-width: 33%;
        }
    }
</style>
